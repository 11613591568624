// Handle Configuration (Environment Variables)
const {
    CFG_SITE_BASE_PATH,
    CFG_API_BASE_URL,
    CFG_ORG_TREE_ROOT_USER_ID = "",
    CFG_LICENCE_ADMIN_EV_DAYS = 99,
    CFG_PARTNER_SALES_RENEWAL_EV_DAYS = 3,
    CFG_TATA_TEL_CALL_API_URL = '',
    CFG_TATA_TEL_CALL_API_AUTH_TOKEN = '',
    RAZORPAY_KEY = "",
  } = require("./config");

  export const RAZORPAY_PAYMENT_KEY = RAZORPAY_KEY;

export const SITE_BASE_PATH = CFG_SITE_BASE_PATH;
export const API_BASE_URL = CFG_API_BASE_URL;
export const ORG_TREE_ROOT_USER_ID = CFG_ORG_TREE_ROOT_USER_ID;
export const LICENCE_ADMIN_EV_DAYS = CFG_LICENCE_ADMIN_EV_DAYS;
export const PARTNER_SALES_RENEWAL_EV_DAYS = CFG_PARTNER_SALES_RENEWAL_EV_DAYS;

export const TATA_TEL_CALL_API_URL = CFG_TATA_TEL_CALL_API_URL;
export const TATA_TEL_CALL_API_AUTH_TOKEN = CFG_TATA_TEL_CALL_API_AUTH_TOKEN;

export const HEADER_FILE_NAME = 'k-file-name';
export const APP_AUTH_TOKEN = 'kdksoft-token';

export const PARTNER_CREDIT_LIMIT_DAYS=365;

export const PLAN_TYPE = [
    {label:'DIFM',value:'CA'},
    {label:'DIY', value:'CORPORATE'},
    {label:'Enterprise', value:'ENTERPRISE'}
]
export const PROFILE_TYPE = [
    {label:'DIFM',value:'CA'},
    {label:'DIY', value:'CORPORATE'},

]
export const PAYMENT_VAI = [
    {label:'CASH',value:'CASH'},
    {label:'CHEQUE',value:'CHEQUE'},
    {label:'IMPS',value:'IMPS'},
    {label:'NEFT_RTGS',value:'NEFT RTGS'}
]

export const PRODUCT_NAME = [
    {label:'Express IT', value:2},
    {label:'Express GST', value:1}
]

export const PLAN_DURATION = [
    {label:'Day', value:'DAY'},
    {label:'Month', value:'MONTH'},
    {label:'Year', value:'YEAR'}
]

export const UI_RESPONSIVE_DATA =  {
    "plan_id" : '',
    "plan_name" : "",
    "organization_type" : "",
    "product_id" : "",
    "is_visible" : false,
    "plan_status" : false,
    "plan_amt" : null,
    "plan_duration" : null,
    "plan_duration_unit" : "",  
    "excluded_plan_feature_id": [],
    "plan_parameters": [],
    'plan_version_id':'',
    'sub_product_id': "",
    "sub_product_name":"",
    "renewal_year_1_amt": "",
    "renewal_year_2_amt": "",
    "renewal_year_3_amt": "",
}

export const EDIT_SAVE_PLAN_JSON =
{
    "plan_name":'Plan Name',
    "organization_type":'Plan Type',
    "product_id":'Product Name',
    "is_visible":'Public/Private',
    "plan_amt":'Plan Amount' ,
    "plan_duration":'Plan Validity' ,
    "plan_duration_unit":'Plan Validity',  
    "excluded_plan_feature_id":'Plan Features', 
    "plan_parameters":'Plan Parameters',
    "renewal_year_1_amt": "Renewal Year 1 Amount",
    "renewal_year_2_amt": "Renewal Year 2 Amount",
    "renewal_year_3_amt": "Renewal Year 3 Amount",
}

export const PLAN_STATUS = {
    'ACTIVE': 'Active',
    'TRIAL': 'Trial',
    'TRIAL_EXPIRED': 'Trial Expired',
    'EXPIRED': 'Expired',
    'RENEWAL': 'Renewal',
    'CHANGE_PLAN': 'Change Plan',
    'NEW': 'New',
    'ADDON': 'Addon',
}
export const USER_ROLE_STATUS = {
    'FINANCE': 'Employee Finance',
    'SALES': 'Employee Sales',
    'RENEWAL': 'Employee Renewal',
    'MIS': 'Employee MIS',
    'CUSTOMER_CARE': 'Employee Customer Care',
    'LICENSE_ADMIN': 'Employee Licence Admin',
    'PARTNER_ADMIN': 'Partner Admin',
    'PARTNER': 'Partner',
    'ADMIN' : 'Admin',
    'SUPER_ADMIN': 'Admin',
}
export const USER_ROLE_DROPDOWN = [
    {label:'ADMIN', value:'ADMIN'},  
    // {label:'SUPER_ADMIN', value:'SUPER_ADMIN'},
    {label:'PARTNER_ADMIN', value:'PARTNER_ADMIN'},
    {label:'LICENSE_ADMIN', value:'LICENSE_ADMIN'},
    {label:'PARTNER', value:'PARTNER'},  
    {label:'SALES', value:'SALES'},
    {label:'CUSTOMER_CARE', value:'CUSTOMER_CARE'},
    {label:'FINANCE', value:'FINANCE'},    
    {label:'RENEWAL', value:'RENEWAL'},
    {label:'MIS', value:'MIS'},
]

export const STATE_LIST = [
    { label: "01-Jammu and Kashmir", value: "01" },
    { label: "02-Himachal Pradesh", value: "02" },
    { label: "03-Punjab", value: "03" },
    { label: "04-Chandigarh", value: "04" },
    { label: "05-Uttarakhand", value: "05" },
    { label: "06-Haryana", value: "06" },
    { label: "07-Delhi", value: "07" },
    { label: "08-Rajasthan", value: "08" },
    { label: "09-Uttar Pradesh", value: "09" },
    { label: "10-Bihar", value: "10" },
    { label: "11-Sikkim", value: "11" },
    { label: "12-Arunachal Pradesh", value: "12" },
    { label: "13-Nagaland", value: "13" },
    { label: "14-Manipur", value: "14" },
    { label: "15-Mizoram", value: "15" },
    { label: "16-Tripura", value: "16" },
    { label: "17-Meghalaya", value: "17" },
    { label: "18-Assam", value: "18" },
    { label: "19-West Bengal", value: "19" },
    { label: "20-Jharkhand", value: "20" },
    { label: "21-Odisha", value: "21" },
    { label: "22-Chattisgarh", value: "22" },
    { label: "23-Madhya Pradesh", value: "23" },
    { label: "24-Gujarat", value: "24" },
    { label: "26-Dadra and Nagar Haveli and Daman and Diu", value: "26" },
    { label: "27-Maharashtra", value: "27" },
    { label: "28-Andhra Pradesh", value: "28" },
    { label: "29-Karnataka", value: "29" },
    { label: "30-Goa", value: "30" },
    { label: "31-Lakshadweep", value: "31" },
    { label: "32-Kerala", value: "32" },
    { label: "33-Tamil Nadu", value: "33" },
    { label: "34-Puducherry", value: "34" },
    { label: "35-Andaman AND Nicobar Islands", value: "35" },
    { label: "36-Telangana", value: "36" },
    { label: "37-Andhra Pradesh", value: "37" },
    { label: "38-Ladakh", value: "38" },
    { label: "97-Other Territory", value: "97" }
];

export const ACCOUNT_TYPE = [
    {label:"Current", value:"current"},
    {label:"Savings", value:"saving"}
]


  export const AGG_PAN_LEVERTURNOVER = [
    { label: "Between 0 & 10 CR", value: "RNG_0_10_CR" },
    { label: "Between 10 & 50 CR", value: "RNG_10_50_CR" },
    { label: "Between 51 & 125 CR", value: "RNG_51_125_CR" },
    { label: "Between 126 & 250 CR", value: "RNG_126_250_CR" },
    { label: "Between 251 & 500 CR", value: "RNG_251_500_CR" },
    { label: "Between 501 & 750 CR", value: "RNG_501_750_CR" },
    { label: "Between 751 & 1000 CR", value: "RNG_751_1000_CR" },
    { label: "Between 1001 & 5000 CR", value: "RNG_1001_5000_CR" },
    { label: "ABOVE 5000 CR", value: "RNG_ABOVE_5001_CR" },
]
  export const PAY_RVCD_BY = [
    {label:"Customer Paid to Me", value:"PARTNER_ACCEPT_CUSTOMER_PAID_PARTNER"},
    {label:"Customer Paid to KDK", value:"PARTNER_ACCEPT_CUSTOMER_PAID_KDK"},
    {label:"Customer Paid to KDK", value:"APPROVED"},
    {label:"Customer Paid to KDK", value:"REJECTED"},
    {label:"Customer Paid to Me", value:"PARTNER_REJECT_CUSTOMER_PAID_PARTNER"}
  ]

 export const SUBSCRIPTION_TYPE = {
    "NEW": "Ni",
    "RENEWAL": "Renewal",
    "ADDON": "Addon",
    "CHANGE_PLAN": "Change Plan"
}

export const PLAN_TYPE_OBJ = {
    'CA':'DIFM',
    'CORPORATE':'DIY',
    'ENTERPRISE':'Enterprise'
}

export const FILTER_SUBS_TYPE = [
    { label: 'Trial', value: 'TRIAL' },
    { label: 'New Installation (Ni)', value: 'NEW' },
    { label: 'Renewal', value: 'RENEWAL' },
    { label: 'Change Plan', value: 'CHANGE_PLAN' },
    { label: 'Addon', value: 'ADDON' },
]
  
export const countryData =
[
        {
            value: 0,
            key: '',
            text: ''
        },
        {
            value: 1,
            key: "93",
            text: "AFGHANISTAN"
        },
        {
            value: 2,
            key: "1001",
            text: "ALAND ISLANDS"
        },
        {
            value: 3,
            key: "355",
            text: "ALBANIA"
        },
        {
            value: 4,
            key: "213",
            text: "ALGERIA"
        },
        {
            value: 5,
            key: "684",
            text: "AMERICAN SAMOA"
        },
        {
            value: 6,
            key: "376",
            text: "ANDORRA"
        },
        {
            value: 7,
            key: "244",
            text: "ANGOLA"
        },
        {
            value: 8,
            key: "1264",
            text: "ANGUILLA"
        },
        {
            value: 9,
            key: "1010",
            text: "ANTARCTICA"
        },
        {
            value: 10,
            key: "1268",
            text: "ANTIGUA AND BARBUDA"
        },
        {
            value: 11,
            key: "54",
            text: "ARGENTINA"
        },
        {
            value: 12,
            key: "374",
            text: "ARMENIA"
        },
        {
            value: 13,
            key: "297",
            text: "ARUBA"
        },
        {
            value: 14,
            key: "61",
            text: "AUSTRALIA"
        },
        {
            value: 15,
            key: "43",
            text: "AUSTRIA"
        },
        {
            value: 16,
            key: "994",
            text: "AZERBAIJAN"
        },
        {
            value: 17,
            key: "1242",
            text: "BAHAMAS"
        },
        {
            value: 18,
            key: "973",
            text: "BAHRAIN"
        },
        {
            value: 19,
            key: "880",
            text: "BANGLADESH"
        },
        {
            value: 20,
            key: "1246",
            text: "BARBADOS"
        },
        {
            value: 21,
            key: "375",
            text: "BELARUS"
        },
        {
            value: 22,
            key: "32",
            text: "BELGIUM"
        },
        {
            value: 23,
            key: "501",
            text: "BELIZE"
        },
        {
            value: 24,
            key: "229",
            text: "BENIN"
        },
        {
            value: 25,
            key: "1441",
            text: "BERMUDA"
        },
        {
            value: 26,
            key: "975",
            text: "BHUTAN"
        },
        {
            value: 27,
            key: "591",
            text: "BOLIVIA (PLURINATIONAL STATE OF)"
        },
        {
            value: 28,
            key: "1002",
            text: "BONAIRE, SINT EUSTATIUS AND SABA"
        },
        {
            value: 29,
            key: "387",
            text: "BOSNIA AND HERZEGOVINA"
        },
        {
            value: 30,
            key: "267",
            text: "BOTSWANA"
        },
        {
            value: 31,
            key: "1003",
            text: "BOUVET ISLAND"
        },
        {
            value: 32,
            key: "55",
            text: "BRAZIL"
        },
        {
            value: 33,
            key: "1014",
            text: "BRITISH INDIAN OCEAN TERRITORY"
        },
        {
            value: 34,
            key: "673",
            text: "BRUNEI DARUSSALAM"
        },
        {
            value: 35,
            key: "359",
            text: "BULGARIA"
        },
        {
            value: 36,
            key: "226",
            text: "BURKINA FASO"
        },
        {
            value: 37,
            key: "257",
            text: "BURUNDI"
        },
        {
            value: 38,
            key: "855",
            text: "CAMBODIA"
        },
        {
            value: 39,
            key: "237",
            text: "CAMEROON"
        },
        {
            value: 40,
            key: "1",
            text: "CANADA"
        },
        {
            value: 41,
            key: "238",
            text: "CAPE VERDE"
        },
        {
            value: 42,
            key: "1345",
            text: "CAYMAN ISLANDS"
        },
        {
            value: 43,
            key: "236",
            text: "CENTRAL AFRICAN REPUBLIC"
        },
        {
            value: 44,
            key: "235",
            text: "CHAD"
        },
        {
            value: 45,
            key: "56",
            text: "CHILE"
        },
        {
            value: 46,
            key: "86",
            text: "CHINA"
        },
        {
            value: 47,
            key: "9",
            text: "CHRISTMAS ISLAND"
        },
        {
            value: 48,
            key: "672",
            text: "COCOS (KEELING) ISLANDS"
        },
        {
            value: 49,
            key: "57",
            text: "COLOMBIA"
        },
        {
            value: 50,
            key: "270",
            text: "COMOROS"
        },
        {
            value: 51,
            key: "242",
            text: "CONGO"
        },
        {
            value: 52,
            key: "243",
            text: "CONGO, THE DEMOCRATIC REPUBLIC OF THE"
        },
        {
            value: 53,
            key: "682",
            text: "COOK ISLANDS"
        },
        {
            value: 54,
            key: "506",
            text: "COSTA RICA"
        },
        {
            value: 55,
            key: "225",
            text: "COTE D'IVOIRE"
        },
        {
            value: 56,
            key: "385",
            text: "CROATIA"
        },
        {
            value: 57,
            key: "53",
            text: "CUBA"
        },
        {
            value: 58,
            key: "1015",
            text: "CURACAO"
        },
        {
            value: 59,
            key: "357",
            text: "CYPRUS"
        },
        {
            value: 60,
            key: "420",
            text: "CZECH REPUBLIC"
        },
        {
            value: 61,
            key: "45",
            text: "DENMARK"
        },
        {
            value: 62,
            key: "253",
            text: "DJIBOUTI"
        },
        {
            value: 63,
            key: "1767",
            text: "DOMINICA"
        },
        {
            value: 64,
            key: "1809",
            text: "DOMINICAN REPUBLIC"
        },
        {
            value: 65,
            key: "593",
            text: "ECUADOR"
        },
        {
            value: 66,
            key: "20",
            text: "EGYPT"
        },
        {
            value: 67,
            key: "503",
            text: "EL SALVADOR"
        },
        {
            value: 68,
            key: "240",
            text: "EQUATORIAL GUINEA"
        },
        {
            value: 69,
            key: "291",
            text: "ERITREA"
        },
        {
            value: 70,
            key: "372",
            text: "ESTONIA"
        },
        {
            value: 71,
            key: "251",
            text: "ETHIOPIA"
        },
        {
            value: 72,
            key: "500",
            text: "FALKLAND ISLANDS (MALVINAS)"
        },
        {
            value: 73,
            key: "298",
            text: "FAROE ISLANDS"
        },
        {
            value: 74,
            key: "679",
            text: "FIJI"
        },
        {
            value: 75,
            key: "358",
            text: "FINLAND"
        },
        {
            value: 76,
            key: "33",
            text: "FRANCE"
        },
        {
            value: 77,
            key: "594",
            text: "FRENCH GUIANA"
        },
        {
            value: 78,
            key: "689",
            text: "FRENCH POLYNESIA"
        },
        {
            value: 79,
            key: "1004",
            text: "FRENCH SOUTHERN TERRITORIES"
        },
        {
            value: 80,
            key: "241",
            text: "GABON"
        },
        {
            value: 81,
            key: "220",
            text: "GAMBIA"
        },
        {
            value: 82,
            key: "995",
            text: "GEORGIA"
        },
        {
            value: 83,
            key: "49",
            text: "GERMANY"
        },
        {
            value: 84,
            key: "233",
            text: "GHANA"
        },
        {
            value: 85,
            key: "350",
            text: "GIBRALTAR"
        },
        {
            value: 86,
            key: "30",
            text: "GREECE"
        },
        {
            value: 87,
            key: "299",
            text: "GREENLAND"
        },
        {
            value: 88,
            key: "1473",
            text: "GRENADA"
        },
        {
            value: 89,
            key: "590",
            text: "GUADELOUPE"
        },
        {
            value: 90,
            key: "1671",
            text: "GUAM"
        },
        {
            value: 91,
            key: "502",
            text: "GUATEMALA"
        },
        {
            value: 92,
            key: "1481",
            text: "GUERNSEY"
        },
        {
            value: 93,
            key: "224",
            text: "GUINEA"
        },
        {
            value: 94,
            key: "245",
            text: "GUINEA-BISSAU"
        },
        {
            value: 95,
            key: "592",
            text: "GUYANA"
        },
        {
            value: 96,
            key: "509",
            text: "HAITI"
        },
        {
            value: 97,
            key: "1005",
            text: "HEARD ISLAND AND MCDONALD ISLANDS"
        },
        {
            value: 98,
            key: "6",
            text: "HOLY SEE (VATICAN CITY STATE)"
        },
        {
            value: 99,
            key: "504",
            text: "HONDURAS"
        },
        {
            value: 100,
            key: "852",
            text: "HONG KONG"
        },
        {
            value: 101,
            key: "36",
            text: "HUNGARY"
        },
        {
            value: 102,
            key: "354",
            text: "ICELAND"
        },
        {
            value: 103,
            key: "91",
            text: "INDIA"
        },
        {
            value: 104,
            key: "62",
            text: "INDONESIA"
        },
        {
            value: 105,
            key: "98",
            text: "IRAN, ISLAMIC REPUBLIC OF"
        },
        {
            value: 106,
            key: "964",
            text: "IRAQ"
        },
        {
            value: 107,
            key: "353",
            text: "IRELAND"
        },
        {
            value: 108,
            key: "1624",
            text: "ISLE OF MAN"
        },
        {
            value: 109,
            key: "972",
            text: "ISRAEL"
        },
        {
            value: 110,
            key: "5",
            text: "ITALY"
        },
        {
            value: 111,
            key: "1876",
            text: "JAMAICA"
        },
        {
            value: 112,
            key: "81",
            text: "JAPAN"
        },
        {
            value: 113,
            key: "1534",
            text: "JERSEY"
        },
        {
            value: 114,
            key: "962",
            text: "JORDAN"
        },
        {
            value: 115,
            key: "7",
            text: "KAZAKHSTAN"
        },
        {
            value: 116,
            key: "254",
            text: "KENYA"
        },
        {
            value: 117,
            key: "686",
            text: "KIRIBATI"
        },
        {
            value: 118,
            key: "850",
            text: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"
        },
        {
            value: 119,
            key: "82",
            text: "KOREA, REPUBLIC OF"
        },
        {
            value: 120,
            key: "965",
            text: "KUWAIT"
        },
        {
            value: 121,
            key: "996",
            text: "KYRGYZSTAN"
        },
        {
            value: 122,
            key: "856",
            text: "LAO PEOPLE 'S DEMOCRATIC REPUBLIC"
        },
        {
            value: 123,
            key: "371",
            text: "LATVIA"
        },
        {
            value: 124,
            key: "961",
            text: "LEBANON"
        },
        {
            value: 125,
            key: "266",
            text: "LESOTHO"
        },
        {
            value: 126,
            key: "231",
            text: "LIBERIA"
        },
        {
            value: 127,
            key: "218",
            text: "LIBYA"
        },
        {
            value: 128,
            key: "423",
            text: "LIECHTENSTEIN"
        },
        {
            value: 129,
            key: "370",
            text: "LITHUANIA"
        },
        {
            value: 130,
            key: "352",
            text: "LUXEMBOURG"
        },
        {
            value: 131,
            key: "853",
            text: "MACAO"
        },
        {
            value: 132,
            key: "389",
            text: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"
        },
        {
            value: 133,
            key: "261",
            text: "MADAGASCAR"
        },
        {
            value: 134,
            key: "265",
            text: "MALAWI"
        },
        {
            value: 135,
            key: "60",
            text: "MALAYSIA"
        },
        {
            value: 136,
            key: "960",
            text: "MALDIVES"
        },
        {
            value: 137,
            key: "223",
            text: "MALI"
        },
        {
            value: 138,
            key: "356",
            text: "MALTA"
        },
        {
            value: 139,
            key: "692",
            text: "MARSHALL ISLANDS"
        },
        {
            value: 140,
            key: "596",
            text: "MARTINIQUE"
        },
        {
            value: 141,
            key: "222",
            text: "MAURITANIA"
        },
        {
            value: 142,
            key: "230",
            text: "MAURITIUS"
        },
        {
            value: 143,
            key: "269",
            text: "MAYOTTE"
        },
        {
            value: 144,
            key: "52",
            text: "MEXICO"
        },
        {
            value: 145,
            key: "691",
            text: "MICRONESIA, FEDERATED STATES OF"
        },
        {
            value: 146,
            key: "373",
            text: "MOLDOVA, REPUBLIC OF"
        },
        {
            value: 147,
            key: "377",
            text: "MONACO"
        },
        {
            value: 148,
            key: "976",
            text: "MONGOLIA"
        },
        {
            value: 149,
            key: "382",
            text: "MONTENEGRO"
        },
        {
            value: 150,
            key: "1664",
            text: "MONTSERRAT"
        },
        {
            value: 151,
            key: "212",
            text: "MOROCCO"
        },
        {
            value: 152,
            key: "258",
            text: "MOZAMBIQUE"
        },
        {
            value: 153,
            key: "95",
            text: "MYANMAR"
        },
        {
            value: 154,
            key: "264",
            text: "NAMIBIA"
        },
        {
            value: 155,
            key: "674",
            text: "NAURU"
        },
        {
            value: 156,
            key: "977",
            text: "NEPAL"
        },
        {
            value: 157,
            key: "31",
            text: "NETHERLANDS"
        },
        {
            value: 158,
            key: "687",
            text: "NEW CALEDONIA"
        },
        {
            value: 159,
            key: "64",
            text: "NEW ZEALAND"
        },
        {
            value: 160,
            key: "505",
            text: "NICARAGUA"
        },
        {
            value: 161,
            key: "227",
            text: "NIGER"
        },
        {
            value: 162,
            key: "234",
            text: "NIGERIA"
        },
        {
            value: 163,
            key: "683",
            text: "NIUE"
        },
        {
            value: 164,
            key: "15",
            text: "NORFOLK ISLAND"
        },
        {
            value: 165,
            key: "1670",
            text: "NORTHERN MARIANA ISLANDS"
        },
        {
            value: 166,
            key: "47",
            text: "NORWAY"
        },
        {
            value: 167,
            key: "968",
            text: "OMAN"
        },
        {
            value: 168,
            key: "92",
            text: "PAKISTAN"
        },
        {
            value: 169,
            key: "680",
            text: "PALAU"
        },
        {
            value: 170,
            key: "970",
            text: "PALESTINE, STATE OF"
        },
        {
            value: 171,
            key: "507",
            text: "PANAMA"
        },
        {
            value: 172,
            key: "675",
            text: "PAPUA NEW GUINEA"
        },
        {
            value: 173,
            key: "595",
            text: "PARAGUAY"
        },
        {
            value: 174,
            key: "51",
            text: "PERU"
        },
        {
            value: 175,
            key: "63",
            text: "PHILIPPINES"
        },
        {
            value: 176,
            key: "1011",
            text: "PITCAIRN"
        },
        {
            value: 177,
            key: "48",
            text: "POLAND"
        },
        {
            value: 178,
            key: "14",
            text: "PORTUGAL"
        },
        {
            value: 179,
            key: "1787",
            text: "PUERTO RICO"
        },
        {
            value: 180,
            key: "974",
            text: "QATAR"
        },
        {
            value: 181,
            key: "262",
            text: "REUNION"
        },
        {
            value: 182,
            key: "40",
            text: "ROMANIA"
        },
        {
            value: 183,
            key: "8",
            text: "RUSSIAN FEDERATION"
        },
        {
            value: 184,
            key: "250",
            text: "RWANDA"
        },
        {
            value: 185,
            key: "1006",
            text: "SAINT BARTHELEMY"
        },
        {
            value: 186,
            key: "290",
            text: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"
        },
        {
            value: 187,
            key: "1869",
            text: "SAINT KITTS AND NEVIS"
        },
        {
            value: 188,
            key: "1758",
            text: "SAINT LUCIA"
        },
        {
            value: 189,
            key: "1007",
            text: "SAINT MARTIN (FRENCH PART)"
        },
        {
            value: 190,
            key: "508",
            text: "SAINT PIERRE AND MIQUELON"
        },
        {
            value: 191,
            key: "1784",
            text: "SAINT VINCENT AND THE GRENADINES"
        },
        {
            value: 192,
            key: "685",
            text: "SAMOA"
        },
        {
            value: 193,
            key: "378",
            text: "SAN MARINO"
        },
        {
            value: 194,
            key: "239",
            text: "SAO TOME AND PRINCIPE"
        },
        {
            value: 195,
            key: "966",
            text: "SAUDI ARABIA"
        },
        {
            value: 196,
            key: "221",
            text: "SENEGAL"
        },
        {
            value: 197,
            key: "381",
            text: "SERBIA"
        },
        {
            value: 198,
            key: "248",
            text: "SEYCHELLES"
        },
        {
            value: 199,
            key: "232",
            text: "SIERRA LEONE"
        },
        {
            value: 200,
            key: "65",
            text: "SINGAPORE"
        },
        {
            value: 201,
            key: "1721",
            text: "SINT MAARTEN (DUTCH PART)"
        },
        {
            value: 202,
            key: "421",
            text: "SLOVAKIA"
        },
        {
            value: 203,
            key: "386",
            text: "SLOVENIA"
        },
        {
            value: 204,
            key: "677",
            text: "SOLOMON ISLANDS"
        },
        {
            value: 205,
            key: "252",
            text: "SOMALIA"
        },
        {
            value: 206,
            key: "28",
            text: "SOUTH AFRICA"
        },
        {
            value: 207,
            key: "1008",
            text: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"
        },
        {
            value: 208,
            key: "211",
            text: "SOUTH SUDAN"
        },
        {
            value: 209,
            key: "35",
            text: "SPAIN"
        },
        {
            value: 210,
            key: "94",
            text: "SRI LANKA"
        },
        {
            value: 211,
            key: "249",
            text: "SUDAN"
        },
        {
            value: 212,
            key: "597",
            text: "SURItext"
        },
        {
            value: 213,
            key: "1012",
            text: "SVALBARD AND JAN MAYEN"
        },
        {
            value: 214,
            key: "268",
            text: "SWAZILAND"
        },
        {
            value: 215,
            key: "46",
            text: "SWEDEN"
        },
        {
            value: 216,
            key: "41",
            text: "SWITZERLAND"
        },
        {
            value: 217,
            key: "963",
            text: "SYRIAN ARAB REPUBLIC"
        },
        {
            value: 218,
            key: "886",
            text: "TAIWAN, PROVINCE OF CHINA"
        },
        {
            value: 219,
            key: "992",
            text: "TAJIKISTAN"
        },
        {
            value: 220,
            key: "255",
            text: "TANZANIA, UNITED REPUBLIC OF"
        },
        {
            value: 221,
            key: "66",
            text: "THAILAND"
        },
        {
            value: 222,
            key: "670",
            text: "TIMOR-LESTE"
        },
        {
            value: 223,
            key: "228",
            text: "TOGO"
        },
        {
            value: 224,
            key: "690",
            text: "TOKELAU"
        },
        {
            value: 225,
            key: "676",
            text: "TONGA"
        },
        {
            value: 226,
            key: "1868",
            text: "TRINvalueAD AND TOBAGO"
        },
        {
            value: 227,
            key: "216",
            text: "TUNISIA"
        },
        {
            value: 228,
            key: "90",
            text: "TURKEY"
        },
        {
            value: 229,
            key: "993",
            text: "TURKMENISTAN"
        },
        {
            value: 230,
            key: "1649",
            text: "TURKS AND CAICOS ISLANDS"
        },
        {
            value: 231,
            key: "688",
            text: "TUVALU"
        },
        {
            value: 232,
            key: "256",
            text: "UGANDA"
        },
        {
            value: 233,
            key: "380",
            text: "UKRAINE"
        },
        {
            value: 234,
            key: "971",
            text: "UNITED ARAB EMIRATES"
        },
        {
            value: 235,
            key: "44",
            text: "UNITED KINGDOM"
        },
        {
            value: 236,
            key: "2",
            text: "UNITED STATES"
        },
        {
            value: 237,
            key: "1009",
            text: "UNITED STATES MINOR OUTLYING ISLANDS"
        },
        {
            value: 238,
            key: "598",
            text: "URUGUAY"
        },
        {
            value: 239,
            key: "998",
            text: "UZBEKISTAN"
        },
        {
            value: 240,
            key: "678",
            text: "VANUATU"
        },
        {
            value: 241,
            key: "58",
            text: "VENEZUELA, BOLIVARIAN REPUBLIC OF"
        },
        {
            value: 242,
            key: "84",
            text: "VIET NAM"
        },
        {
            value: 243,
            key: "1284",
            text: "VIRGIN ISLANDS, BRITISH"
        },
        {
            value: 244,
            key: "1340",
            text: "VIRGIN ISLANDS, U.S."
        },
        {
            value: 245,
            key: "681",
            text: "WALLIS AND FUTUNA"
        },
        {
            value: 246,
            key: "1013",
            text: "WESTERN SAHARA"
        },
        {
            value: 247,
            key: "967",
            text: "YEMEN"
        },
        {
            value: 248,
            key: "260",
            text: "ZAMBIA"
        },
        {
            value: 249,
            key: "263",
            text: "ZIMBABWE"
        },
        {
            value: 250,
            key: "9999",
            text: "OTHERS"
        }
    ]
