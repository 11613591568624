/* eslint-disable array-callback-return */
import React from 'react';

import { withRouter } from 'react-router-dom';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { SITE_BASE_PATH } from '../constant';
import { Header } from './Header.jsx';
import { getItems } from '../api/common';
import HeaderPartner from './HeaderPartner.jsx';

const items = getItems();

const userRole = localStorage.getItem('sd-user-role');

class DrawerRouterContainer extends React.Component {
    state = {
        expanded: false,
        selectedId: items.findIndex(x => x.selected === true),
        isSmallerScreen: window.innerWidth < 768,
        permissions: []
    }

    resizeWindow = () => {
        this.setState({ isSmallerScreen: window.innerWidth < 768 })
    }

    handleClick = () => {
        this.setState((e) => ({ expanded: !e.expanded }));
    }

    handleSelect = (e) => {
        if (e.itemTarget.props.name.toLowerCase() === 'logout') {
            this.logOut();
        }
        else {
            this.setState({ selectedId: e.itemIndex, expanded: false });
            this.props.history.push(e.itemTarget.props.route);
        }

    }

    logOut = () => {
        localStorage.setItem('kdksoft-token', null);
        localStorage.setItem('sd-user-role', null);
        localStorage.setItem('sd-user-full_name', null);
        this.props.history.push(`${SITE_BASE_PATH}login`);
    };

    getSelectedItem = (pathName) => {

        let currentPath = items.find(item => {
            if (item.selectitems && pathName.indexOf(item.selectitems) > 0) {
                return item.selectitems
            }
        });
        if (currentPath && currentPath.name) {
            return currentPath.name;
        }
    }

    componentDidUpdate() {
        try {
            const parent = window.parent;
            if (parent) {
                parent.postMessage({ url: this.props.location.pathname, demo: true }, "*")
            }
        } catch (err) {
            console.warn('Cannot access iframe')
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeWindow, false)
        this.resizeWindow();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindow)
    }


    render() {
        let selected = this.getSelectedItem(this.props.location.pathname);
        const localizationService = provideLocalizationService(this);

        if (["PARTNER", "SALES", "RENEWAL"].includes(userRole)) {
            return (<React.Fragment> <HeaderPartner userRole={userRole} />
                <DrawerContent >
                    {this.props.children}
                </DrawerContent>
            </React.Fragment>)
        } else {
            if (this.props.location.pathname.indexOf('login') > 0 || this.props.location.pathname.indexOf('forgetpass') > 0) {
                return (<React.Fragment> {this.props.children}</React.Fragment>)
            }
            else {
                return (
                    <React.Fragment>
                        <Header
                            onButtonClick={this.handleClick}
                            page={localizationService.toLanguageString(`custom.${selected}`)}
                        />
                        <Drawer
                            expanded={this.state.expanded}
                            animation={{ duration: 100 }}
                            items={items.map((item) => ({
                                ...item,
                                text: `${item.name}`,
                                selected: item.name === selected
                            }))
                            }
                            position='start'
                            mode={this.state.isSmallerScreen ? 'overlay' : 'push'}
                            mini={this.state.isSmallerScreen ? false : true}
                            onOverlayClick={this.handleClick}
                            onSelect={this.handleSelect}
                        >
                            <DrawerContent >
                                {this.props.children}
                            </DrawerContent>
                        </Drawer>
                    </React.Fragment>
                );
            }
        }
    }
};

registerForLocalization(DrawerRouterContainer);

export default withRouter(DrawerRouterContainer);

