import React, { useState } from 'react';
import './HeaderPartner.scss';
import SdLogo from '../images/subscriptiondetail.png';
import { useLocation, useHistory, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { SITE_BASE_PATH, USER_ROLE_STATUS } from '../constant';
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../redux/headerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


const HeaderPartner = (props) => {

    const { userRole } = props;
    const NOTAUTH_PATH = [
        `${SITE_BASE_PATH}profile-details`,
        `${SITE_BASE_PATH}permission-denied`,
    ];
    const location = useLocation();
    const history = useHistory();
    const header_data = useSelector(selectHeaderData);

    const user_full_name = localStorage.getItem('sd-user-full_name');

    const [show, setShow] = useState(false);

    const logOut = () => {
        localStorage.setItem('kdksoft-token', null);
        localStorage.setItem('sd-user-role', null);
        localStorage.setItem('sd-user-full_name', null);
        history.push(`${SITE_BASE_PATH}login`);
    };


    const LOGIN_PATHS = [
        `${SITE_BASE_PATH}login`,
        `${SITE_BASE_PATH}signup`,
        `${SITE_BASE_PATH}forgetpass`,
        `${SITE_BASE_PATH}resetpass`,
        `${SITE_BASE_PATH}maintenance`,
    ];


    return (
        <header className="sd_partneradmin_headersection">
            <div className="header-sticky"></div>
            <nav className="navbar">
                <div className="container-fluid">

                    <div className="navbar-header col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6" style={{display: 'flex'}}>
                        <Link className="navbar-brand" to={SITE_BASE_PATH}>
                            <img src={SdLogo} alt='software logo' style={{ width: "145px" }} />
                        </Link>

                        {!LOGIN_PATHS.includes(location.pathname)
                            &&
                            <div className="userprodropd mr-2">
                                <ul className="float-left mt-2 pt-1">
                                    <li style={{ float: 'left', }}>
                                        <div className="" style={{ textAlign: 'left', color: 'rgb(49, 113, 155)', fontSize: '12px', fontWeight: '700' }}>
                                            <span className='sd_clientname_overflowtext'>
                                                <span class="pl-0" data-toggle="tooltip" data-placement="top" title={header_data?.full_name ?? user_full_name}>
                                                    {header_data?.full_name ?? user_full_name}
                                                </span>
                                            </span>
                                        </div>
                                        <span className="sd_partneradmin_sync_status_partner" style={{ fontSize: '12px', fontWeight: '500' }}>
                                            <span className="sd_partneradmin_boxalign">
                                                <span className='sd_partneradmin_boxalign sd_clientnameuerrole_overflowtext'>
                                                    <span class="pl-0" data-toggle="tooltip" data-placement="top" title={USER_ROLE_STATUS?.[userRole] ?? ''}>
                                                    {USER_ROLE_STATUS?.[userRole] ?? ''}
                                                    </span>
                                                </span>
                                                
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    {!LOGIN_PATHS.includes(location.pathname)
                        &&
                        <div className="userprodropdlist">
                            <ul className="float-right mb-2 pb-2">
                                {/* <li style={{ float: 'left', marginRight: '4rem' }}>
                                    <div className="" style={{ textAlign: 'left', color: 'rgb(49, 113, 155)', fontSize: '12px', fontWeight: '700' }}>
                                        <span>{header_data?.full_name ?? user_full_name}</span>
                                    </div>
                                    <span className="sd_partneradmin_sync_status_partner" style={{ fontSize: '12px', fontWeight: '500' }}>
                                        <span className="sd_partneradmin_boxalign">
                                            {USER_ROLE_STATUS?.[userRole] ?? ''}
                                        </span>
                                    </span>
                                </li> */}
                                <li className="nav-item dropdown profileuserpaddtop cursor_pointer" style={{ top: '7px', right: "0.5rem" }}>
                                    <span className="nav-link dropdown-toggle"  id="navbardrop" data-toggle="dropdown" aria-expanded="false" onClick={() => setShow(!show)}>
                                        <span className="profile_imgbgclr">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                            </svg>
                                        </span>
                                    </span>
                                    <div className={`dropdown-menu profileuserdropdshow ${show ? "show" : ""}`}>
                                    {!NOTAUTH_PATH.includes(location.pathname) && <>
                                        <span className="black_color_text usertextoverflow"
                                            style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}>
                                            <span className="pl-0" data-toggle="tooltip" data-placement="top"
                                                title={header_data?.full_name ?? user_full_name}
                                            >
                                                {header_data?.full_name ?? user_full_name}
                                            </span>
                                        </span>
                                        <span className="activeuserpro">
                                            <FontAwesomeIcon icon={faCircle} />
                                        </span>
                                        <div className="dropdown-divider" />
                                        <Button
                                            className="dropdown-item btn btn-primary sd_userdroplist_textfs"
                                            onClick={() => {
                                                history.push(`${SITE_BASE_PATH}profile`);
                                                setShow(!show)
                                            }}
                                            style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}
                                        >
                                            Profile Settings
                                        </Button>
                                        
                                        <div className="dropdown-divider" />
                                        </>}
                                        <Button className="dropdown-item logdisinline sd_userdroplist_textfs" id="zps_logout_button" onClick={(e) => logOut(e)} style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}>
                                            <span className="black_color_text" style={{ paddingLeft: '0px' }}>Logout</span>
                                            <span className="prousersigupicon">
                                                <span className={'k-icon k-i-logout'} />
                                            </span>

                                        </Button>
                                    </div>
                                </li>
                            </ul>
                        </div>}
                </div>
            </nav>
        </header>
    )
}

export default HeaderPartner