import React from 'react';
import { Link } from 'react-router-dom';
import { SITE_BASE_PATH } from '../constant';

const NotFound = () => {

  const userRole = localStorage.getItem('sd-user-role');

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '50px',
    }}>
      <h1>404 - Not Found!</h1>
      {['PARTNER', 'SALES', 'RENEWAL'].includes(userRole) ?
        <Link to={`${SITE_BASE_PATH}`}>
          Go Home
        </Link>
        :
        <Link to={`${SITE_BASE_PATH}plan/published/list`}>
          Go Home
        </Link>
      }
    </div>
  );

};

export default NotFound;
